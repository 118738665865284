<div class="container-fluid px-0" *ngIf="main==true" style="height: 100vh;">
  <div [routerLink]="['/']" class="col-12 mx-0 py-2 d-flex justify-content-between px-3 align-items-center" style="background-color: #323031;">
    <img src="../../../../assets/icons/Sidra Logo white-16 (1).svg" alt="" width="70">
    <span class="fz-13 fw-500 cursor" style="color: white;" [routerLink]="['/']">
        Return to Home page
    </span>
</div>
  <div class="col-12 d-flex justify-content-center py-5" style="background-color: #F4F3F0;">
      <div class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
        <div class="col-12 mx-0 px-0">
          <div class="col-12 d-flex justify-content-center">
              <span class="fz-22 fw-500">Get Started</span>
          </div>
          <div class="col-12 d-flex justify-content-center my-0 py-0">
              <span class="fz-13 c-585858 mt-2">Fill all field below and register your account</span>
          </div>
          <div class="col-12 mt-4 mb-3">
              <input type="email" class="form-control fz-12 h-55"  placeholder="First Name" [(ngModel)]="cus_fname">
              <span class="fz-11 text-danger" *ngIf="cus_fname_fill==true">fill the first name</span>
          </div> 
          <div class=" col-12 mt-2 mb-3">
              <input type="email" class="form-control fz-12 h-55"  placeholder="Last Name" [(ngModel)]="cus_lname">
              <span class="fz-11 text-danger" *ngIf="cus_lname_fill==true">fill the last name</span>
          </div> 
          <div class="col-12 mt-2 mb-3">
            <input type="email" class="form-control fz-12 h-55"  placeholder="Email Address" [(ngModel)]="cus_email">
            <span class="fz-11 text-danger" *ngIf="cus_email_fill==true">fill the email address</span>
          </div>
          <div class="col-12 mt-2 mb-3">
              <!-- <input type="text" [(ngModel)]="contact" class="form-control fz-12 h-55"  placeholder="Phone Number"> -->
              <div class="form-control d-flex flex-row px-0 py-0" style="position: relative;">
                  <select class="form-select w-25 px-1" style="border-radius: 0px; outline: none; box-shadow: none;border: none; height: 38px;" [(ngModel)]="country_code" (change)="onSelectCode($event)">
                      <option *ngFor="let con of contry_code_list" [value]="con.code"> {{con.code}}</option>
                  </select> 
                  <input class="fz-12 w-100" id="quantity" name="quantity" maxlength="15" type="text" (input)="onInput($event)" [(ngModel)]="cus_mobile" placeholder="eg:558678579"  
                  style="border-right: 1px solid #f6f6f6;border-left: none;border-bottom: none;border-top: none; outline: none;">
              </div>
            <span class="fz-11 text-danger" *ngIf="cus_mobile_fill==true">fill the Mobile number</span>
          </div> 
          <div class="col-12 row mx-0 mb-3">
              <div class="col ps-0">
                <select class="px-2 w-100" aria-label="Default select example" (change)="onChanegegender($event)">
                    <option selected>Select Gender</option>
                    <option value="F">Female</option> 
                    <option value="M">Male</option>
                    <option value="O">I prefer not to say</option>
                </select>  
                <span class="fz-11 text-danger" *ngIf="cus_gender_fill==true">Please Select gender</span>
              </div>  
              <div class="col pe-0">
                <select class="px-2 w-100" (change)="currentCountry($event)">
                    <option selected>
                        Select Country
                    </option> 
                    <option *ngFor="let con of country" [value]="con">{{con}}</option>
                </select>
                <span class="fz-11 text-danger" *ngIf="cus_country_fill==true">
                    Please select the country
                </span>
              </div> 
          </div>  
          <div class="col-12  mb-3">
            <mat-form-field appearance="fill" class="w-100 form-control  f-12">
              <!-- <mat-label>Enter your password</mat-label> -->
              <input class="fz-12 fw-500" [(ngModel)]="cus_pass" matInput [type]="hide ? 'password' : 'text'" placeholder="Enter the Password" style="font-weight: 500 !important;color: #212529 !important;" (focus)="inputTouched = true">
              <button class="fz-12 eye-icon" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon class="">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button> 
            </mat-form-field>
            <div class="d-felx align-items-center mt-2" style="color:red;" *ngIf="cus_pass.length !< 8" >
              <i class="bi bi-exclamation-circle fz-14 "></i>
              <span class="fz-10 ms-2">The password should be at least 8 characters long.</span>
            </div>
          </div>
          <div class="col-12 row mx-0 d-flex align-items-center">
              <div class="form-check d-flex align-items-center mb-0">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="termsAnduse($event)" [(ngModel)]="isChecked">
                  <label class="form-check-label fz-12 fw-500 ms-2 mt-1 cursor" for="exampleCheck1">I accept all 
                      <i class="c-FF9900 mx-1 fz-12 fw-500" [routerLink]="['/genaral-policy']" routerLinkActive="router-link-active" > 
                          Terms Of Use 
                      </i>  
                      and 
                      <i class="c-FF9900 mx-1 fz-12 fw-500" [routerLink]="['/genaral-policy']"> 
                        Privacy Policy 
                      </i> 
                  </label> 
              </div>            
          </div>
          <div class="col-12 row px-0 mx-0 mt-3" >
              <button *ngIf="isLoading==false" class="login-btn fz-15 fw-400" [disabled]="cus_fname=='' || cus_lname==''|| cus_email=='' || country_code=='' || cus_mobile=='' || cus_gender==null || cus_gender=='Select Gender' || cus_country==null || cus_country=='Select Country'|| cus_pass.length < 8 || isChecked==false " (click)="onSignUp()">Register</button>
              <button *ngIf="isLoading==true"  class="login-btn fz-15 fw-400">
                <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
          </div>
        </div>
        <hr> 
        <div class="d-flex justify-content-center cursor">
            <span class="fz-13 fw-500 c-5F5F61">Already have an account ? <i class="fz-13 fw-500 c-FF9900 ms-1" [routerLink]="['/auth']" routerLinkActive="router-link-active" >Sign in</i></span>
        </div>
      </div>
  </div>
</div>

 <!-- chatbot -->
 <div class="chatbot_1" *ngIf="chatbotbtn==true">
  <div class="d-none d-sm-flex">
      <button type="button" class="chatbot_btn px-4 py-2" (click)="chatbot()"><img class="me-2"
              src="../../../assets/images/chatbot.svg" alt=""> May i help you</button>
  </div>

  <!-- chatbot btn mobile responsive  -->
  <div class="d-flex d-sm-none">
      <button type="button" class="chatbot_btn_r" (click)="chatbot()"><img class=""
              src="../../../assets/images/chatbot.svg" alt=""></button>
  </div>
</div>

<div class="chatbot_2 d-none d-sm-flex " *ngIf="chatbotmsgtab==true">
  <div class=" rounded">
      <div class="chatbot_header rounded-top d-flex justify-content-between align-items-center px-2 py-1">
          <span class="fw-bold"><img class="me-2" src="../../../assets/images/chatbot.svg" alt="">Sidra Bot</span>
          <i class="bi bi-x-square cursor" (click)="close()"></i>
      </div>

      <div #chatbox class="chatbot_textarea shadow-sm" style="height:400px; overflow: auto;" >
          <ng-container *ngFor="let message of inputArray; let i = index">
              <div *ngIf="message !== null" class="chatbot_textarea d-flex justify-content-end p-3">
                  <div class="p-lg-3 p-3 " style="background-color: #FFECCF; border-radius: 12px 12px 0px 12px; max-width: 75%;">
                      <span class="input_msg">{{ message }}</span>
                  </div>
              </div>
              <div class="chatbot_textarea d-flex justify-content-start flex-column px-3 " [class.pt-0]="message !== null" [class.pb-2]="message !== null">
                  <div class="p-lg-3 p-3 w-75 mb-1"
                      style="background-color: #F2F2F2; border-radius: 12px 12px 12px 0px;">
                      <span class="output_msg" style="text-align: justify;">{{ outputArray[i] }}</span>
                  </div>
              
                  <div class="d-flex justify-content-start" *ngFor="let btn of button[i]; let j = index">                        
                      <button *ngIf="showAllButtons || j < initialButtonCount" class="w-75 py-2 mb-1 btn_msg"
                           (click)="chatbtnclick(i, btn.payload, btn.title)" [disabled]="buttonDisabledStates[i]">
                        {{ btn.title }}
                      </button>
                    </div>
                    
                    <span type="button" class="show-more-button w-75" (click)="toggleShowAllButtons()"*ngIf="button[i].length > 3">
                      {{ showAllButtons ? 'Show Less' : 'Show More' }}
                    </span>
                    
              </div>
          </ng-container>
          <!-- <img *ngIf="loading" src="../../../assets/images/Animation - 1693981992613.gif" alt="Loading..." width="80" /> -->
      </div>

      <div class="form-control d-flex justify-content-between rounded-bottom shadow-sm"
          style="border-radius: 0px;border: none; border-top: 1px;">

          <input type="text" placeholder="Text your message ..." class="form-control fz-13 chatbot-textarea"
              [(ngModel)]="input_send" style="border: none; height: auto;"  (keydown.enter)="send()">

          <div class="d-flex justify-content-center cursor" (click)="send()" style="border-radius: 50%;">
              <img src="../../../assets/images/send.svg" width="20px" alt="">
          </div>
      </div>

  </div>
</div>

<!-- chatbot mobile responsive  -->

<div class="chatbot" *ngIf="chatbotmsgtab==true">
  <div class="d-flex d-sm-none ">
      <div class="chatbot_header_r  d-flex justify-content-between align-items-center px-3 py-1">
          <span class="fw-bold"><img class="me-2" src="../../../assets/images/chatbot.svg" alt="">Sidra Bot</span>
          <i class="bi bi-x-square cursor" (click)="close()"></i>
      </div>

      <div #chatbox class="chatbot_textarea shadow-sm py-5" style="height: 98vh; overflow: auto; width: 100%;" >
          <ng-container *ngFor="let message of inputArray; let i = index" >
              <div *ngIf="message !== null" class="chatbot_textarea d-flex justify-content-end p-3">
                  <div class="p-3" style="background-color: #FFECCF; border-radius: 15px 15px 0px 15px; max-width: 75%;">
                      <span class="input_msg">{{ message }}</span>
                  </div>
              </div>
              <!-- <img src="../../../assets/images/Animation - 1693981992613.gif" alt="Loading..." width="80" /> -->
              <div class="chatbot_textarea d-flex justify-content-start flex-column px-3" [class.pt-3]="message !== null" [class.pb-2]="message !== null">
                  <div class="p-3 w-75 mb-1"
                      style="background-color: #F2F2F2; border-radius: 15px 15px 15px 0px;">
                      <span class="output_msg">{{ outputArray[i] }}</span>
                  </div>
                
                  <div class="d-flex justify-content-start" *ngFor="let btn of button[i]; let j = index">
                      <!-- Use ngIf to conditionally display buttons based on the showAllButtons flag -->
                      <button *ngIf="showAllButtons || j < initialButtonCount" class="w-75 py-1 mb-1 btn_msg"
                      [disabled]="buttonDisabledStates[i]"
                           (click)="chatbtnclick(i, btn.payload, btn.title)">
                        {{ btn.title }}
                      </button>
                    </div>
                    
                    <span type="button" class="show-more-button w-75" (click)="toggleShowAllButtons()"*ngIf="button[i].length > 4">
                      {{ showAllButtons ? 'Show Less' : 'Show More' }}
                    </span>
              </div>
          </ng-container>
      </div>

      <div class="text_input form-control d-flex justify-content-between rounded-bottom shadow-sm"
          style="border-radius: 0px;border: none; border-top: 1px;">

          <input type="text" placeholder="Text your message ..." class="form-control fz-13 chatbot-textarea"
              [(ngModel)]="input_send" style="border: none;" (keydown.enter)="send()">

          <div class="d-flex justify-content-center cursor" (click)="send()" style="border-radius: 50%;">
              <img src="../../../assets/images/send.svg" width="20px" alt="">
          </div>
      </div>

  </div>
</div>


