import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Product, ProductRealChild } from 'src/app/models/product';
import { MessengerService } from 'src/app/services/messenger.service';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { WishlistService } from 'src/app/services/wishlist.service';

@Component({
  selector: 'app-product-card-four',
  templateUrl: './product-card-four.component.html',
  styleUrls: ['./product-card-four.component.scss']
})
export class ProductCardFourComponent  {
  @Input() wishlist = false
  @Input() control = true
  @Input() products!: ProductRealChild;
  @Input() wishListed = false
  @Output() cartEvent = new EventEmitter()
  @Output() wishEvent = new EventEmitter()
  @Output() isWishListEvent = new EventEmitter()
  is_addtocart=true
  is_count=false
  count:any=1
  is_wish_active:any
  is_wishlisted=false
  wishlistArray:any=[]
  wishListId:any
  wishListActived=false
  groupListArray:any=[]
  isGroupActive=false
  isDefaultActive=false
  withLoginwishList=false
  currentQty = 1
  maxQty:any
  minMumQty:any
  adderDisable=false
  reduceDisable=false
  inCartIsActive=false
  withAndWithoutLoginBtn=false
  allCartProduct:any=[]
  withOutLoginArray:any=[]
  inCart=false
  dataKey="cartData"
  constructor(
    private messageService: MessengerService,
    private router: Router,
    private cart:CartService,
    private wish:WishlistService
  ) { }

  ngOnInit(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let min:any
    let max:any
    if ((this.products?.min_order_limit != null && this.products?.min_order_limit != 0) || (this.products?.max_order_limit != null && this.products?.max_order_limit != 0)) {
      min  = (this.products?.min_order_limit != null && this.products?.min_order_limit != 0) ? this.products?.min_order_limit : 1 
      max  = (this.products?.max_order_limit != null && this.products?.max_order_limit  != 0 && this.products?.max_order_limit  > this.products?.stock_count) ? this.products?.stock_count : this.products?.max_order_limit 
    }else{
      max   = (this.products?.max_order_limit  == null || this.products?.max_order_limit  == 0 ) ? (this.products?.stock_count > 0) ? this.products?.stock_count : 10 : 10
      min = 1
    } 
    this.currentQty=min 
    this.maxQty=max
    this.minMumQty=min 
    if(userDetails!=null){
      this.withLoginwishList=true
      this.withAndWithoutLoginBtn=true
      this.wish.getWishListGroup().subscribe((d:any)=>{
        this.groupListArray=d.data.results
        if(this.groupListArray.length!=0){
          this.isGroupActive=true
        }else{ 
          this.isGroupActive=false
        }
        if(this.groupListArray.length==1){
          let name=null
          name=this.groupListArray.filter((d:any)=>d.group_name=="default")
          if(name!=null){
            this.isDefaultActive=true
          }else{
            this.isDefaultActive=false
          }
        }
        
      })
      this.wish.userWishListId().subscribe((d:any)=>{
        this.wishlistArray=d.data?.added_list
        this.wishListId=this.wishlistArray.some((item:any)=>item==this.products.id)
        if(this.wishListId==true){
          this.is_wishlisted=true
        }else{
          this.is_wishlisted=false
        }
        
      })
      this.cart.getCartProduct().subscribe((data:any)=>{
        this.allCartProduct=data.data?.added_list
        this.inCart=this.allCartProduct.some((item:any)=>item==this.products.id)
        if(this.inCart==true){
          this.inCartIsActive=true
          this.is_addtocart=false
        }
        else{
          this.inCartIsActive=false
          this.is_addtocart=true
        } 
      })
    }else{
      this.withLoginwishList=false
      this.withAndWithoutLoginBtn=false
      let history:any =  localStorage.getItem(this.dataKey)
      this.withOutLoginArray = JSON.parse(history)
      if(this.withOutLoginArray!=null){
        this.inCart=this.withOutLoginArray.some((item:any)=>item.variant_id==this.products.id)
      }
      if(this.inCart==true){
        this.inCartIsActive=true
        this.is_addtocart=false
        this.withAndWithoutLoginBtn=true
      } 
      else{
        this.inCartIsActive=false
        this.is_addtocart=true 
        this.withAndWithoutLoginBtn=false
      }
    } 
  }

  changeWishlist(product: any) {
    this.wishListed = !this.wishListed
    this.wishEvent.emit(product)
  }

  addCart(prod: any) {
    this.cartEvent.emit(prod)
  }
  
  navigateProductPage(id:any){
    this.router.navigate(['/product/' + id])
  } 

  addToCart(prod:any){
    this.cart.addToCart(prod,this.currentQty) 
    this.messageService.get().subscribe((d:any)=>{
      if(d=='success'){
        this.inCartIsActive=true
        this.is_addtocart=false
      }
    })
  }

  addToCartLocal(prod:any){
    this.inCartIsActive=true
    this.is_addtocart=false
    this.cart.addToLocalCart(prod,this.currentQty) 
  }

  adder(prod:any){
    if(this.maxQty>10){
      this.maxQty=10
    }
    if(this.currentQty  < this.maxQty){
      this.currentQty=this.currentQty+1
      this.cart.addToCart(prod, this.currentQty) 
    }else{
      this.adderDisable=true
    }
    
  }

  reduce(prod:any){
    if(this.currentQty > this.minMumQty){
      this.currentQty=this.currentQty-1
      this.cart.addToCart(prod,this.currentQty) 
    }else{
      this.reduceDisable=true
      this.is_addtocart=true
      this.is_count=false
      this.cart.removeSidraCart(prod.id)
    }
  }

  movetoAnother(item:any,id:any,index:any){
    // this.ismove_active=true
    let wish1={
      variant_id:item.id,
      group_id:id,
      varient_code:item.code,
      inventory_code:item.inventory_id
    }
    this.wish.createWishlist(wish1) 
    this.is_wish_active=index  
    this.is_wishlisted=true
    this.wishListActived=false
  } 

  wishListdropDown(item:any){
    if(this.is_wishlisted==true){
      this.wishListActived=false
      this.wish.unWishlisted(this.products.id)
      this.messageService.getWish().subscribe((d:any)=>{
        if(d=='success'){
          this.is_wishlisted=false
          this.is_wish_active=null
        } 
      }) 
    }
    else if(this.is_wishlisted==false&&this.isGroupActive==false){ 
      this.wishListActived=true
      this.is_wish_active=null
      this.is_wishlisted=true
      let name=null
      name=this.groupListArray.filter((d:any)=>d.group_name=="default")
      this.wishListActived=false
      this.is_wish_active=null
      let wish1:any={
        variant_id:item.id,
        group_id:null,
        varient_code:item.code,
        inventory_code:item.inventory_id
      }
      this.wish.createWishlist(wish1) 
      // this.is_wish_active=index  
    } 
    else if(this.is_wishlisted==false&&this.isGroupActive==true&&this.isDefaultActive==false){ 
      this.wishListActived=true
      this.is_wish_active=null
    } 
    else if(this.is_wishlisted==false&&this.isGroupActive==true&&this.isDefaultActive==true){ 
      let name=null
      name=this.groupListArray.filter((d:any)=>d.group_name=="default")
      this.wishListActived=false
      this.is_wish_active=null
      let wish1:any={
        variant_id:item.id,
        group_id:name[0]?.id,
        varient_code:item.code,
        inventory_code:item.inventory_id
      }
      this.wish.createWishlist(wish1) 
      // this.is_wish_active=index  
      this.is_wishlisted=true
      this.wishListActived=false
    } 
  } 

}
