import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { environment } from 'src/environments/environment';

declare const UIkit: any;
@Component({
  selector: 'app-sellwithusregistration',
  templateUrl: './sellwithusregistration.component.html',
  styleUrls: ['./sellwithusregistration.component.scss']
})
export class SellwithusregistrationComponent implements OnInit {
  contry_code_list = environment.conutryList
  Country:any
  country_code: any = "971"
  public password: string = '';
  public showPassword: boolean = false;
  registerpage = true
  successpage = false
  BusinessName: any = ''
  PersonName: any = ''
  BusinessEmail: any = ''
  invalidEmail: boolean = false;
  BusinessContactNo: any = ''
  invalidPhoneNumber: boolean = false;
  BusinessAddress: any = ''
  TRN: any = ''
  Password: any = ''
  Trade: any = null
  Tax: any = null
  Tradeid: any
  Taxid: any
  address = false
  modal = true
  fileUploaded: boolean = false;

  isChecked = false
  firstform = true
  secondform = false
  form = true
  otpDisplay = false
  code:any

  street: any = null
  district: any = null
  country: any = ""
  state: any = ''
  Address: any = null
  location: any = ''
  locationAddressList: any
  optionsOfLoc: any = "default"
  markers: any = []
  lat: any = 0
  lng: any = 0
  zoom = 20
  currentLocation = ""
  markerPosition: any
  userAddress: string = ''
  userLatitude: any = ''
  userLongitude: any = ''
  avaliableLocation = false
  defaultAdress: any

  map!: google.maps.Map
  service!: google.maps.places.PlacesService;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    maxZoom: 15,
    minZoom: 8,
    mapTypeControl: false
  }
  subscribeTimer = 20
  timeLeft = 20
  addressotp: any = ""
  formattedAddress: string = '';
  constructor(
    private cart: CartService, private auth: AuthService,
    private router: Router, private http: HttpClient, private msg: MessengerService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {



    this.cart.getDefaultAddress().subscribe((d: any) => {
      this.defaultAdress = d.data?.value
      this.locationAddressList = {
        country: this.defaultAdress?.country,
        state: this.defaultAdress?.state,
        district: this.defaultAdress?.city,
        street: this.defaultAdress?.street,
        location: this.defaultAdress?.location

      }
      this.msg.getOtp().subscribe((d: any) => {
        this.avaliableLocation = d.data.value
      })
      this.userLatitude = parseFloat(this.defaultAdress?.latitude)
      this.userLongitude = parseFloat(this.defaultAdress?.longitude)
      this.markers = []
      this.markers.push({
        position: {
          lat: this.userLatitude,
          lng: this.userLongitude
        },
        length: 16,
        label: {
          color: 'red',
          text: this.currentLocation,
        },
        title: this.currentLocation + (this.markers.length + 3),
        options: { animation: google.maps.Animation.DROP },
      })
      this.markerPosition = { lat: this.userLatitude, lng: this.userLongitude };
      this.center = {
        lat: this.userLatitude,
        lng: this.userLongitude
      }
      let data = {
        country: this.locationAddressList?.country,
        state: this.locationAddressList?.state,
        city_or_town: this.locationAddressList?.street,
        location: this.locationAddressList?.location,
        longitude: this.userLatitude.toString(),
        latitude: this.userLongitude.toString()
      }
      this.auth.avaliableLocation(data)
      this.msg.getOtp().subscribe((d: any) => {
        this.avaliableLocation = d.data.value
      })
    })
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  privacy(name:any){
    if(name=="Terms Of Use"){
      window.open('http://localhost:4200/#/termsanduse', '_blank');
    }
    if(name=="Privacy Policy"){
      window.open('http://localhost:4200/#/genaral-policy', '_blank');
    }
  }

  validateEmail() {
    const email = this.BusinessEmail;
    if (email === '') {
      this.invalidEmail = false; // Reset to valid when empty
    } else if (!this.isValidEmail(email)) {
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }
  }

  isValidEmail(email: string): boolean {
    // A simple email format validation regex
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  validatePhoneNumber() {
    const phoneNumber = this.BusinessContactNo;

    if (phoneNumber === '') {
      this.invalidPhoneNumber = false; // Reset to valid when empty
    } else if (!this.isValidPhoneNumber(phoneNumber)) {
      this.invalidPhoneNumber = true;
    } else {
      this.invalidPhoneNumber = false;
    }
  }

  isValidPhoneNumber(phoneNumber: string): boolean {
    // A simple phone number format validation regex
    const phoneNumberPattern = /^[0-9]{9,11}$/;
    return phoneNumberPattern.test(phoneNumber);
  }

  reuploadFile() {
    this.fileUploaded = false;
    this.Trade = ''; // Clear the Trade value if needed
    // Additional logic to reset file upload state
  }

  onFileChangeTrade(event: any) {
    const Input = event.target;
    const trade = <File>event.target.files[0]
    if (trade.size > 1024 * 1024) { // Check if file size is more than 1MB
      this.toaster.warning("File size should not exceed 1MB.", '', { positionClass: 'toast-bottom-center' })
      return;
    }
    const fd: any = new FormData();
    fd.append('upload', trade)
    this.http.post("https://api-rgc-user.hilalcart.com/file-upload", fd).toPromise().then((d: any) => {

      if (d.status == 'success') {
        this.Tradeid = d.data.id
        if (Input.files.length > 0) {
          this.Trade = Input.files[0].name;
        } else {
          this.Trade = '';
        }
        this.toaster.success(d.message, '', { positionClass: 'toast-bottom-center' })
      } else {
        this.toaster.warning(d.message, '', { positionClass: 'toast-bottom-center' })
      }
    })

  }
  triggerFileInputClick(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  onFileChangeTax(event: any) {
    const Input = event.target;
    const tax = <File>event.target.files[0]
    if (tax.size > 1024 * 1024) { // Check if file size is more than 1MB
      this.toaster.warning("File size should not exceed 1MB.", '', { positionClass: 'toast-bottom-center' })
      return;
    }
    const fd: any = new FormData();
    fd.append('upload', tax)
    this.http.post("https://api-rgc-user.hilalcart.com/file-upload", fd).toPromise().then((d: any) => {

      if (d.status == 'success') {
        this.Taxid = d.data.id
        if (Input.files.length > 0) {
          this.Tax = Input.files[0].name;
        } else {
          this.Tax = '';
        }
        this.toaster.success(d.message, '', { positionClass: 'toast-bottom-center' })
      } else {
        this.toaster.warning(d.message, '', { positionClass: 'toast-bottom-center' })
      }
    })

  }

  first() {
    if (this.firstform) {
      this.firstform = false;
      this.secondform = true;
      window.scrollTo(0, 0);
    } else if (this.secondform) {
      this.secondform = false;
      this.otpDisplay = true;
    }
  }


  Back() {
    this.firstform = true
    this.secondform = false

  }

  termsAnduse(e: any) {
    if (e.target.checked == true) {
      this.isChecked = true
    } else {
      this.isChecked = false
    }
  }

  confirmLocations() {
    this.country = this.locationAddressList?.country
    this.state = this.locationAddressList?.state
    this.district = this.locationAddressList?.district
    this.street = this.locationAddressList?.street
    this.address = true
    this.modal = false
    UIkit.modal('#location_address').hide();
  }

  closemap() {
    UIkit.modal('#location_address').hide();
  }

  onMapClick(event: any) {
    this.markers = []
    this.markers.push({
      position: {
        lat: event.latLng?.lat(),
        lng: event.latLng?.lng(),
      },
      length: 16,
      label: {
        color: 'red',
        text: this.currentLocation,
      },
      title: this.currentLocation + (this.markers.length + 3),
      options: { animation: google.maps.Animation.DROP },
    })

    this.markerPosition = { lat: event.latLng?.lat(), lng: event.latLng?.lng() };
    this.center = {
      lat: event.latLng?.lat(), lng: event.latLng?.lng()
    }
    this.AddressTaken(event.latLng?.lat(), event.latLng?.lng())

  }

  getCurrentLocation() {
    this.optionsOfLoc = "current"
    if (navigator.geolocation) {
      this.markers = []
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.markers.push({
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          length: 16,
          label: {
            color: 'red',
            text: this.currentLocation,
          },
          title: this.currentLocation + (this.markers.length + 3),
          options: { animation: google.maps.Animation.DROP },
        })
        this.markerPosition = { lat: this.lat, lng: this.lng };
        this.center = {
          lat: this.lat, lng: this.lng
        }
        this.AddressTaken(this.lat, this.lng)
      });
    }
  }

  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
    this.markers = []
    this.markers.push({
      position: {
        lat: this.userLatitude,
        lng: this.userLongitude
      },
      length: 16,
      label: {
        color: 'red',
        text: this.currentLocation,
      },
      title: this.currentLocation + (this.markers.length + 3),
      options: { animation: google.maps.Animation.DROP },
    })
    this.markerPosition = { lat: this.userLatitude, lng: this.userLongitude };
    this.center = {
      lat: this.userLatitude,
      lng: this.userLongitude
    }
    this.AddressTaken(this.userLatitude, this.userLongitude)
  }

  AddressTaken(lat: any, lng: any) {
    const geocoder = new google.maps.Geocoder();
    const latlng = {
      lat: lat,
      lng: lng
    };
    geocoder.geocode({ location: latlng  }, (results: any, status: any) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          let country, state, district, street=null, location = null;
          for (const component of addressComponents) {
            const types = component.types;
            if (types.includes('country')) {
              country = component.long_name;
            } else if (types.includes('administrative_area_level_1')) {
              state = component.long_name;
            } else if (types.includes('neighborhood')) {
              district = component.long_name;
            }
            else if (types.includes('route')) {
              street = component.long_name;
            }
            else if (types.includes('sublocality')) {
              location = component.long_name;
            }
          }
          this.locationAddressList = {
            country: country,
            state: state,
            district: district,
            street: street,
            location: location
          }
          this.country = this.locationAddressList?.country
          this.state = this.locationAddressList?.state
          this.district = this.locationAddressList?.district
          this.street = this.locationAddressList?.street
          this.location = this.locationAddressList?.location
        
         
        }
      }
    })
  }


  // AddressTaken(lat: any, lng: any) {
  //   const geocoder = new google.maps.Geocoder();
  //   const latlng = {
  //     lat: lat,
  //     lng: lng
  //   };
  //   geocoder.geocode({ location: latlng  }, (results: any, status: any) => {
  //     if (status === google.maps.GeocoderStatus.OK) {
  //       if (results[0]) {
  //         const addressComponents = results[0].address_components;
  //         let country, state, district, street, location = null;
  //         for (const component of addressComponents) {
  //           console.log("component",component);

  //           const types = component.types;
  //           if (types.includes('country')) {
  //             country = component.long_name;
  //             console.log("country", this.country);


  //             if (country == 'United Arab Emirates') {
  //                if (types.includes('administrative_area_level_1')) {
  //                 state = component.long_name;
  //                 console.log("statestatestate",state);
  //               } else if (types.includes('administrative_area_level_3')) {
  //                 district = component.long_name;
  //               }
  //               else if (types.includes('neighborhood')) {
  //                 street = component.long_name;
  //               }
  //               else if (types.includes('sublocality')) {
  //                 location = component.long_name;
  //               }
  //             }else if (country == 'India'){
  //               if (types.includes('administrative_area_level_1')) {
  //                 state = component.long_name;
  //               } else if (types.includes('administrative_area_level_3')) {
  //                 district = component.long_name;
  //               }
  //               else if (types.includes('locality')) {
  //                 street = component.long_name;
  //               }
  //               else if (types.includes('sublocality')) {
  //                 location = component.long_name;
  //               }
  //             }
  //           } 
  //         }
  //         console.log("addressComponents1212121234343565656", addressComponents);
  //         console.log("locationAddressList1212121234343565656",  this.locationAddressList);

  //         this.locationAddressList = {
  //           country: country,
  //           state: state,
  //           district: district,
  //           street: street,
  //           location: location
  //         }
  //         this.country = this.locationAddressList?.country
  //         this.state = this.locationAddressList?.state
  //         this.district = this.locationAddressList?.district
  //         this.street = this.locationAddressList?.street
  //         this.location = this.locationAddressList?.location
  //         console.log("country", this.country);
  //         console.log("state", this.state);
  //         console.log("district", this.district);
  //         console.log("street", this.street);
  //         console.log("location", this.location);
        
          
  //       }
  //     }
  //   })
  // }

  submitForm() {
    let sellerform = {
      business_name: this.BusinessName,
      business_mail: this.BusinessEmail,
      business_phone:"+"+this.country_code + this.BusinessContactNo,
      tax_numner: this.TRN,
      business_person_name: this.PersonName,
      state: this.state,
      city: this.district,
      country: this.country,
      password: this.Password,
      address: this.street,
      location: this.location,
      longitude: this.lng,
      latitude: this.lat

    }
    this.auth.sellersignup(sellerform)
    this.msg.getOTP().subscribe((d: any) => {
      if (d.status == "success") {
        this.form = false
        this.otpDisplay = true  
        window.scrollTo(0, 0);    
        this.code=d.data.code
        this.observableTimer()
      }
    })
  }


  // this.registerpage=false
  // this.successpage=true

  reSendOtp() {
   
    this.http.get("https://api-rgc-user.hilalcart.com/partner_partnerurer/resend-otp/"+this.code).toPromise().then((d: any) => {

    if (d.status == 'success') {

      this.toaster.success(d.message, '', { positionClass: 'toast-bottom-center' })
    } else {
      this.toaster.warning(d.message, '', { positionClass: 'toast-bottom-center' })
    }
  })
    this.observableTimer()
  }

  observableTimer() {
    const source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      if (val > this.timeLeft) return
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  OtpVarification() {
let sellerverify = {
    code:this.code,
    otp:this.addressotp,
    proof_id_1:this.Tradeid,
    proof_id_2:this.Taxid
}
this.auth.sellerverification(sellerverify)
this.msg.getsuccess().subscribe((d: any) => {
  if (d.status == "success") {
  this.registerpage=false
  this.successpage=true
    
  }
})

  }

  // onOtpChange(e:any){
  //   this.addressotp=e
  //   this.button_active=this.addressotp.length==5?true:false
  // }

}
