<div  *ngIf="isLoading" class="container-fluid  loading-overlay d-flex justify-content-center align-items-center" style="height:100%">
    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
</div>
<div class="pt-5 mt-md-2 mt-1"></div>
<div class="sidra-header-main_two pt-1   w-100" (mouseleave)="ifHeaderActive=false">
    <div class="col-12 row mx-0 d-flex align-items-center px-sm-4 px-1 pt-lg-0 pt-0">
        <div class="col-11 col-sm-8 d-flex flex-row  py-0 px-0  align-items-center">
            <!-- <div class="d-flex flex-row cursor linercolor py-2" [class.all_cateSelect]="ifHeaderActive==true"  (click)="allCate()">
                <i class="bi bi-list cw fw-500"></i>
                <span class="cw fz-14 fw-400 ms-2">All</span>
            </div> -->
            <div class="ms-0 uk-position-relative uk-visible-toggle uk-light  py-3 cursor" tabindex="-1" uk-slider
                finite>
                <ul class="uk-slider-items uk-child-width-auto uk-grid uk-grid-small">
                    <li>
                        <span class="fw-400 fz-14 cw  linercolor_11 pb-2 ms-0" (click)="allCate()">
                            <i class="bi bi-list cw fw-500"></i>
                            <span class="cw fz-14 fw-400 ms-2">All</span>
                        </span>
                    </li>
                    <li *ngFor="let item of segmentArray;let j=index">
                        <span class="fw-400 fz-14 cw  linercolor_11 pb-1 ms-2" [class.linercolor_1]="segmentName==j"
                            (click)="segmentClick(j,item.name)" routerLinkActive="router-link-active">{{item.name|titlecase}}
                        </span>
                    </li>
                    <li>
                        <span *ngIf="userData!=null" class="fw-400 fz-14 cw  linercolor_11 pb-1 ms-2"
                            [class.linercolor_1]="wishlistName=='wishlist'" (click)="wishListRouting('wishlist')"
                            routerLinkActive="router-link-active">Wishlist
                        </span>
                    </li>
                    <li>
                        <span class="fw-400 fz-14 cw  linercolor_11 pb-1 ms-2"
                            [class.linercolor_1]="wishlistName=='myoffer'" (click)="wishListRouting('myoffer')"
                            routerLinkActive="router-link-active">My Offers
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="userData!=null"
            class="col-1 col-sm-4 d-flex justify-content-end align-items-center cursor py-0 px-0">
            <div class="px-sm-2 px-1 d-flex align-items-center location" uk-toggle="target:#location_address">
                <img src="../../../assets/icons/location_on.svg" alt="" width="13">
                <span class="fz-13 ms-2  d-none d-sm-block" style="color: white;">Delivery To :
                    <i class="fw-500 ms-1">
                        {{street}}, Ajman
                    </i>
                </span>
            </div>
            <!-- <div class="ms-2">

                <img src="../../../assets/icons/infinity.svg" alt="" width="30" height="30">

                <span class="fz-14 cw fw-400 ms-2">Explore Infinity</span>

            </div> -->
        </div>
    </div>
    <div *ngIf="ifHeaderActive==true" class="all_category d-flex">
        <div class="all_category__main w-100 py-0 px-lg-3 px-1 d-none d-sm-block">
            <div class="col-12 row mx-0">
                <div class="col-lg-2 col-md-3 col-4  ps-0 pt-2 pb-3 border-end pe-1">

                    <div class="bgw all-cate">

                        <div class="pe-0">

                            <div class="d-flex justify-content-between py-2 px-2 cursor"
                                *ngFor="let item of allCategory;let i=index" [class.all-cate_hover]="i==subIndex">

                                <span class="fz-13 fw-500" (mouseover)="getSubCate(item.code,i)">

                                    {{item.name |titlecase}}

                                </span>

                                <span class="fz-13 fw-500 cursor ms-1">

                                    <img src="../../../assets/assets/right.svg" width="6" alt="">

                                </span>

                            </div>

                        </div>

                    </div>

                </div>
                <div class=" col-lg-5 col-md-5 col-sm col-6 border-end py-2 all-cate">

                    <div class="d-flex flex-row">

                        <div class="mx-0 d-flex flex-column col-lg-4 col border-end">

                            <div class="py-2 cursor d-flex flex-column cursor ms-md-2 ms-0"
                                *ngFor="let item of allSubCategory;let j=index" [class.c-FF9900]="j==groupIndex">

                                <span class="fz-13 fw-500" (mouseover)="groupIndex=j">

                                    {{item.name |titlecase}}

                                </span>

                            </div>

                        </div>

                        <div class="py-2 col  ps-md-3 ps-1 mx-0">

                            <div class="cursor  pb-3 ms-xl-2 ms-0 d-flex flex-row">

                                <div class="w-100 d-flex flex-lg-row flex-column flex-wrap">

                                    <span class="w-50 fz-13 fw-400 pb-3 c-6D6D6D"
                                        *ngFor="let item of allSubCategory[groupIndex]?.group"
                                        (click)="clickRoute(item.code,item.data_type,item.name)">{{item.name
                                        |titlecase}}
                                    </span>

                                </div>

                            </div>

                            <div class="d-block d-md-none">
                                <div class="col-12 mx-0 px-0 border-bottom py-3">
                                    <span class="fw-bold fz-14 pt-3 pb-3 ms-1">
                                        BRANDS
                                    </span>
                                </div>
                                <div class="col-12 row mx-0  mt-2 flex-wrap ">

                                    <div class="col-1 me-2 round mt-2  px-1 cursor d-flex justify-content-center align-items-center"
                                        *ngFor="let item of allSubCategory[groupIndex]?.brands;"
                                        (click)="clickRoute(item.code,item.data_type,item.name)">

                                        <img src="{{item.image}}" alt="" width="50">

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div class="col-lg-5 col-4 all-cate px-2 d-none d-md-block">

                    <div class="mx-2 d-none d-md-block">

                        <div class="col-12 mx-0 px-0 border-bottom py-3">

                            <span class="fw-bold fz-14 pt-3 pb-3 ms-1">

                                BRANDS

                            </span>

                        </div>

                        <div class="col-12 row mx-0 px-2 mt-2 flex-wrap">

                            <div class="col-1 me-2 round mt-2 px-lg-2 px-1 cursor d-flex justify-content-center align-items-center"
                                *ngFor="let item of allSubCategory[groupIndex]?.brands;"
                                (click)="clickRoute(item.code,item.data_type,item.name)">

                                <img src="{{item.image}}" alt="" width="50">

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="all_category__main1 w-100  d-block d-sm-none">
            <div class="col-12 row mx-0">
                <div class=" bgw col-10 mx-0 px-0 d-flex justify-content-between pe-1">

                    <span class="fw-bold fz-16 pt-3 pb-3 ms-1" *ngIf="isAllcategory==true">

                        All Categries

                    </span>

                    <span class="fw-bold fz-14 pt-3 pb-3 ms-1" *ngIf="isAllcategory!=true" (click)="Back()"><span
                            class="fw-bold" uk-icon="arrow-left"></span>

                        Back

                    </span>

                    <button class="me-2" type="button" (click)="all_category__main()" uk-close></button>

                </div>
                <div class="col-10 py-2 px-2 bgw all-cate1" *ngIf="isAllcategory==true">

                    <div class="pe-0">

                        <div class="d-flex justify-content-between py-2 px-2 cursor"
                            *ngFor="let item of allCategory;let i=index" (click)="onCategoryClick(item.code,i)"
                            [class.all-cate_hover]="i==subIndex">

                            <span class="fz-13 fw-500">

                                {{item.name |titlecase}}

                            </span>

                            <span class="fz-13 fw-500 cursor ms-1">

                                <img src="../../../assets/assets/right.svg" width="6" alt="">

                            </span>

                        </div>

                    </div>

                </div>
                <div class="col-10 bgw px-2  py-2 all-cate1" *ngIf="isSubCategories==true">

                    <div class="d-flex flex-row">

                        <div class="mx-0 d-flex flex-column col ">

                            <div class="py-2 cursor d-flex flex-column cursor ms-md-2 ms-0"
                                *ngFor="let item of allSubCategory; let j = index" (click)="onSubCategoryClick(j)"
                                [class.c-FF9900]="j==subIndex">

                                <span class="fz-13 fw-500">

                                    {{item.name |titlecase}}

                                </span>

                            </div>

                        </div>

                    </div>

                </div>
                <div class="col-10 bgw  py-2 px-2 all-cate1" *ngIf="isGroups==true">
                    <div class="d-flex flex-column">
                        <div class="py-2 col  ps-md-3 ps-1 mx-0">

                            <div class="cursor  pb-3 ms-xl-2 ms-0 d-flex flex-row">

                                <div class="w-100 d-flex flex-lg-row flex-column flex-wrap">

                                    <span class="w-100 fz-13 fw-400 pb-3 c-6D6D6D"
                                        *ngFor="let item of getGroups(); let k = index"
                                        (click)="clickRoute(item.code,item.data_type,item.name)">{{item.name
                                        |titlecase}}</span>

                                </div>

                            </div>

                        </div>
                        <div class="">

                            <div class="col-12 mx-0 px-0 border-bottom py-3">

                                <span class="fw-bold fz-14 pt-3 pb-3 ms-1">

                                    BRANDS

                                </span>

                            </div>

                            <div class="col-12 row mx-0 px-2 mt-2 flex-wrap ">

                                <div class="col-1 me-2 round mt-2 px-lg-2 px-1 cursor d-flex justify-content-center align-items-center"
                                    *ngFor="let item of getBrands()"
                                    (click)="clickRoute(item.code,item.data_type,item.name)">

                                    <img src="{{item.image}}" alt="" width="50">

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<div id="location_address" class="uk-flex-top" uk-modal="stack:true">

    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical ps-0 pe-0 pt-0 pb-4">

        <button class="uk-modal-close-default" type="button" uk-close></button>

        <div class="col-12 row mx-0 bg-F4F3F0">

            <div class="col-12 py-3 px-3">

                <div class="d-flex justify-content-between" *ngIf="editAddressIsacctive==true"
                    (click)="listAddress('backList')">

                    <span class="fz-15 fw-500 cursor c-FF9701">Back </span>

                </div>

                <div class="d-flex justify-content-between" *ngIf="isFullMap==true" (click)="listAddress('map')">

                    <span class="fz-15 fw-500">Location </span>

                </div>

                <div class="d-flex  justify-content-between w-100" *ngIf="listAddressData==true">

                    <span class="fz-15 fw-500 cursor c-FF9701" (click)="listAddress('BackLoca')">Back Location </span>

                    <span class="fz-15 fw-500 cursor pe-4 c-FF9701" (click)="listAddress('backNewAddress')">Add New
                        Address List 
                    </span>
                </div>
                <div class=" d-flex justify-content-between" *ngIf="idHalfMapAddress==true">
                     <span class="fz-15 fw-500 cursor">Delivery Address</span>
                     <span class="fz-15 fw-500 cursor c-FF9701 me-3" (click)="listAddress('list')">Delivery Address list</span>
                </div>
            </div>
        </div>

        <div class="col-12 row mx-0 mt-2 h-100">

            <div class="col-12 row mx-0" *ngIf="isFullMap==true">

                <div class="search-box position-relative d-xl-block d-none px-0" style="height: 42px;">

                    <div class="overflow-hidden position-relative h-100">

                        <input class="form-control bg-light fz-13 text-truncate w-100"
                        type="text"  ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"   
                        style="border-radius: 5px !important; border: 1.3px solid #F4F3F1 !important; height: 42px !important;" placeholder="Search location..">

                        <button class="pointer" (click)="handleAddressChange($event)" ><i class="bi bi-search"></i></button>

                    </div>

                </div>

                <div class="col-12 row mx-0 px-0">

                    <google-map class="px-0" height="300px" width="100%" [center]="center" [options]="options"
                        [latitude]="lat" [longitude]="lng" [scrollwheel]="true" (mapClick)="onMapClick($event)"
                        [zoom]="zoom">

                        <map-marker *ngFor="let marker of markers" [position]="marker.position"
                            [options]="marker.options">

                        </map-marker>

                        <label class="current-location-btn" (click)="getCurrentLocation()">

                            <i class="bi bi-geo-alt me-2"></i>Locate Me</label>

                    </google-map>

                </div>

                <div class="col-12 row mx-0 pt-4">
                    <div class="d-flex flex-row d-flex justify-content-center">
                        <button class="py-2 px-3 colseMap_btn fz-14 fw-bold uk-modal-close">
                            Close Map
                        </button>
                        <button class="confirm_btn py-2 px-3 ms-2 fz-14 fw-bold" [disabled]="avaliableLocation==false" (click)="confirmLocations()">
                            Confirm Address
                        </button>
                    </div>
                </div>
            </div>

            <div class="px-0" *ngIf="idHalfMapAddress==true">
                <div class="col-12 row mx-0 mt-2 border-bottom">

                    <div class="col-12 row mx-0">

                        <div class="col-md-6 col-12 mt-3 border-end">

                            <div class="border b-r-5">

                                <google-map class="d-none d-md-block" height="400px" width="100%" [center]="center" [options]="options"
                                    [latitude]="lat" [longitude]="lng" [scrollwheel]="true"
                                    (mapClick)="onMapClick($event)">

                                    <map-marker *ngFor="let marker of markers" [position]="marker.position"
                                        [options]="marker.options">

                                    </map-marker>

                                </google-map>

                                <google-map class="d-block d-md-none" height="300px" width="100%" [center]="center" [options]="options"
                                    [latitude]="lat" [longitude]="lng" [scrollwheel]="true"
                                    (mapClick)="onMapClick($event)">

                                    <map-marker *ngFor="let marker of markers" [position]="marker.position"
                                        [options]="marker.options">

                                    </map-marker>

                                </google-map>

                                <div class="pt-1 px-2">

                                    <span class="fz-12 fw-500">

                                        {{street}},{{district}},{{state}},{{country}}

                                    </span>

                                </div>

                                <div class="d-flex justify-content-end mt-1 pb-2 px-2 cursor" (click)="full_map()">

                                    <span class="fz-12 fw-500 cr-main">

                                        Change Location

                                    </span>

                                </div>

                            </div>

                            <div class="d-flex align-items-center mt-3 mb-2">

                            <input class="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" (click)="makeUsDefault($event)">

                                <label class="form-check-label fz-14 ms-2" for="flexCheckChecked">

                                    Make us default

                                </label>

                            </div>

                        </div>

                        <div class="col-md-6 col-12">

                            <div class="col-12 row mx-0 py-3 px-0">

                                <div class="col-12 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">First Name<span
                                            class="text-danger fz-13 fw-bold ms-1">*</span></label>

                                    <input [(ngModel)]="full_name" type="text"
                                        class="form-control form-control_address fz-12 h-55" placeholder="First Name">

                                </div>

                                <div class="col-12 row mx-0 px-0 mt-2">

                                    <div class="px-0">

                                        <label for="" class="fz-13 fw-500 mb-1">Phone Number</label>

                                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                                    </div>

                                    <!-- <input type="text" [(ngModel)]="contact" class="form-control fz-12 h-55"  placeholder="Phone Number"> -->

                                    <div class="form-control d-flex flex-row px-0 py-0"
                                        style="position: relative;border-radius: 5px; ">

                                        <select class="form-select w-25 pe-1 ps-2"
                                            style="border-radius: 5px !important; outline: none; box-shadow: none;border: none;height: 38px;"
                                             (change)="country">
                                            <option *ngFor="let con of contry_code_list" [value]="con.code">{{con.code}}</option>
                                        </select>
                                        <input class="fz-12 w-100" list="datalistOptions" maxlength="15" type="text"
                                            [(ngModel)]="contact" placeholder="558678579"
                                            style=" outline: none; border: none;border-radius: 5px;" >
                                    </div>
                                </div>

                                <div class="col-12 mt-2 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">Building Name / Room Number<span
                                            class="text-danger fz-13 fw-bold ms-1">*</span></label>

                                    <input type="text" [(ngModel)]="building_name"
                                        class="form-control form-control_address fz-12 h-55"
                                        placeholder="eg.Rashidiyyah">

                                </div>

                                <div class="col-12 mt-2 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">Landmark</label>

                                    <input type="text" [(ngModel)]="landmark"
                                        class="form-control form-control_address fz-12 h-55"
                                        placeholder="eg. near Rawabi market">

                                </div>
                                <!-- <div class="col-12 mt-2 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">Street/city<span
                                            class="text-danger fz-13 fw-bold ms-1">*</span></label>

                                    <input type="text" [(ngModel)]="street"
                                        class="form-control form-control_address fz-12 h-55"
                                        placeholder="eg. near Rawabi market">

                                </div> -->
                            </div>
                            <div class="col-12 row mx-0 pb-sm-0 pb-0 pt-0 px-0 mt-2">

                                <div class="col-12 px-0">

                                    <span class="fz-13 fw-500">Add Delivery Instructions</span>

                                    <div class="d-flex flex-sm-row flex-column mt-2">

                                        <button class="address-btn-two  fz-13  px-1 py-2"
                                            [class.address-btn-twoActive]="instBox=='Avoid ringing bell'"
                                            (click)="instructionsSelection('Avoid ringing bell')">

                                            Avoid ringing bell

                                        </button>

                                        <button class="address-btn-two  fz-13  ms-sm-2 ms-0 mt-sm-0 mt-2  px-1 py-2"
                                            [class.address-btn-twoActive]="instBox=='Hand over to security'"
                                            (click)="instructionsSelection('Hand over to security')">

                                            Hand over to security

                                        </button>

                                        <button class="address-btn-two  fz-13  ms-sm-2 ms-0 mt-sm-0 mt-2  px-1 py-2"
                                            [class.address-btn-twoActive]="instBox=='Leave at the door'"
                                            (click)="instructionsSelection('Leave at the door')">

                                            Leave at the door

                                        </button>

                                        <!-- <textarea class="form-control form-control_address fz-14 textarea ms-2" [(ngModel)]="instructionsTextarea" placeholder="Add custom message ..." rows="2" (click)="textareachange('btnactivefour')"></textarea> -->

                                    </div>

                                </div>

                            </div>
                            <div class="col-12 mt-2 px-0 mb-3">

                                <input type="text" [(ngModel)]="instructionsTextarea"
                                    class="form-control form-control_address fz-12 h-55"
                                    placeholder="eg. near Rawabi market" (click)="textareachange()">

                            </div>
                            <span class="fz-14 fw-500">Location Tag<span class="text-danger  ms-1">*</span></span>
                            <div class="col-12 d-flex mb-3">

                                <div class="d-flex flex-row mt-2 d-flex align-items-center">

                                    <!-- <span class="fz-14 fw-500">Location Tag<span class="text-danger  ms-1">*</span> :</span> -->

                                    <div class="d-flex align-items-center ms-2">

                                        <input class="form-check-input" type="radio" [checked]="address_tag=='Home'"
                                            name="flexRadioDefault" id="flexRadioDefault1"
                                            (click)="locationTag('Home')">

                                        <span class="ms-2 fz-14 fw-400">Home</span>

                                    </div>

                                    <div class="d-flex align-items-center ms-2">

                                        <input class="form-check-input" type="radio" [checked]="address_tag=='Office'"
                                            name="flexRadioDefault" id="flexRadioDefault2"
                                            (click)="locationTag('Office')">

                                        <span class="ms-2 fz-14 fw-400">Office</span>

                                    </div>

                                    <div class="d-flex align-items-center ms-2">

                                        <input class="form-check-input" type="radio" [checked]="address_tag=='Custom'"
                                            name="flexRadioDefault" id="flexRadioDefault3"
                                            (click)="locationTag('Custom')">

                                        <span class="ms-2 fz-14 fw-400">Custom</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-0 d-flex justify-content-center pt-3" (click)="addresCreate()">
                    <button class="confirm_btn px-3 py-2"
                        [disabled]="full_name== '' || contact== ''|| building_name== ''  || street== '' || address_tag==null  ">Create
                        new Address</button>
                </div>
            </div>
            <div class="col-12 row mx-0" *ngIf="listAddressData==true">

                <div class="px-0" style="height: 70vh; overflow: scroll;">

                    <div class="border b-r-5 py-2 mb-2" *ngFor="let item of addressList">

                        <div class="col-12 row mx-0 px-0">

                            <div class="col-12 d-flex flex-column">

                                <div class="d-flex flex-row align-items-center">

                                    <span class="fz-15 fw-bold">

                                        {{item.full_name | titlecase}}

                                    </span>

                                    <button class="defualt-btn ms-3 fz-12"
                                        *ngIf="item.is_default==true">Default</button>

                                    <button class="order-btn py-1 fz-12 ms-2 fw-500 px-1" *ngIf="item.is_default==false"
                                        (click)="default(item.id)">Make us default</button>

                                </div>

                                <span class="fz-14 mt-2">{{item.building_name}}, {{item.street_name}},
                                    {{item.state}}</span>

                                <span class="fz-14 mt-2">{{item.city}},{{item.country}}<i
                                        class="fz-14 fw-bold ms-1"></i></span>

                                <div class="d-flex flex-row mt-3">

                                    <!-- <button class="address-btn fz-14" (click)="edit(item.id)">Edit</button> -->

                                    <!-- <button class="address-btn fz-14 ms-2" (click)="deleteAddress(item.id)">Delete</button> -->

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <div class="container-fluid p-0" *ngIf="editAddressIsacctive==true">

                <div class="col-12 row mx-0 px-4">

                    <div class="col-12 py-3">

                        <div class="d-flex flex-column">

                            <span class="fz-14 fw-500">Location Tag</span>

                            <div class="d-flex flex-row mt-2">

                                <div class="d-flex align-items-center">

                                    <input class="form-check-input" type="radio" [checked]="home==true"
                                        name="flexRadioDefault" id="flexRadioDefault1" (click)="locationTag('Home')">

                                    <span class="ms-2 fz-14 fw-400">Home</span>

                                </div>

                                <div class="d-flex align-items-center ms-3">

                                    <input class="form-check-input" type="radio" [checked]="office==true"
                                        name="flexRadioDefault" id="flexRadioDefault2" (click)="locationTag('Office')">

                                    <span class="ms-2 fz-14 fw-400">Office</span>

                                </div>

                                <div class="d-flex align-items-center ms-3">

                                    <input class="form-check-input" type="radio" [checked]="custom==true"
                                        name="flexRadioDefault" id="flexRadioDefault3" (click)="locationTag('Custom')">

                                    <span class="ms-2 fz-14 fw-400">Custom</span>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="col-12 row mx-0 py-3 px-4">

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">First Name</label>

                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                        <input [(ngModel)]="full_name" type="text" class="form-control fz-12 h-55"
                            placeholder="First Name">

                    </div>

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">Phone Number</label>

                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                        <!-- <input type="text" [(ngModel)]="contact" class="form-control fz-12 h-55"  placeholder="Phone Number"> -->

                        <div class="form-control d-flex flex-row px-0 py-0" style="position: relative;">

                            <select class="form-select w-25 px-1"
                                style="border-radius: 0px; outline: none; box-shadow: none;" [(ngModel)]="country_code"
                                (change)="country">
                                <option *ngFor="let con of contry_code_list" [value]="con.code">{{con.code}}</option>
                            </select>
                            <input class="fz-12 w-100" list="datalistOptions" [(ngModel)]="contact" placeholder="558678579"style="border-right: 1px solid #f6f6f6;border-left: none;border-bottom: none;border-top: none; outline: none;height: 38px">

                        </div>

                    </div>

                </div>

                <div class="col-12 row mx-0 py-3 px-4">

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">Building Name / Room Number</label>

                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                        <input type="text" [(ngModel)]="building_name" class="form-control fz-12 h-55"
                            placeholder="eg.Rashidiyyah">

                    </div>

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">Street / Area</label>

                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                        <input type="text" [(ngModel)]="street_name" class="form-control fz-12 h-55"
                            placeholder="eg.Ajman, UAE">

                    </div>

                </div>

                <div class="col-12 row mx-0 py-3 px-4">

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">City / Emirates</label>

                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                        <input type="text" [(ngModel)]="city" class="form-control fz-12 h-55"
                            placeholder="eg.Ajman, UAE">

                    </div>

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">Landmark</label>

                        <input type="text" [(ngModel)]="landmark" class="form-control fz-12 h-55"
                            placeholder="eg. near Rawabi market">

                    </div>

                </div>

                <div class="col-12 row mx-0 py-3 px-4">

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">Nationality</label>

                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                        <select class="form-select form-control" style=" border: 1.5px solid #ececec;"
                            [(ngModel)]="country" (change)="countryChange($event)">

                            <option *ngFor="let con of countryArray" [value]="con.code">

                                {{con.name}}

                            </option>

                        </select>

                    </div>

                    <div class="col-6">

                        <label for="" class="fz-13 fw-500 mb-2">State</label>

                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                        <select class="form-select form-control" style=" border: 1.5px solid #ececec;"
                            [(ngModel)]="state" (change)="stateChange($event)">

                            <option *ngFor="let con of stateArray" [value]="con.name">{{con.name}}</option>

                        </select>

                    </div>

                </div>

                <div class="col-12 row mx-0 py-3 px-4">

                    <div class="col-12">

                        <span class="fz-13 fw-500">Add Delivery Instructions</span>

                        <div class="d-flex flex-row mt-3">

                            <button class="address-btn-two fw-500"
                                [class.address-btn-twoActive]="instBox=='Avoid ringing bell'"
                                (click)="instructionsSelection('Avoid ringing bell')">

                                Avoid ringing bell

                            </button>

                            <button class="address-btn-two ms-2 fw-500"
                                [class.address-btn-twoActive]="instBox=='Hand over to security'"
                                (click)="instructionsSelection('Hand over to security')">

                                Hand over to security

                            </button>

                            <button class="address-btn-two ms-2 fw-500"
                                [class.address-btn-twoActive]="instBox=='Leave at the door'"
                                (click)="instructionsSelection('Leave at the door')">

                                Leave at the door

                            </button>

                            <textarea class="form-control fz-14 textarea ms-2" [(ngModel)]="instructionsTextarea"
                                placeholder="Add custom message ..." rows="2" (click)="textareachange()"></textarea>

                        </div>

                    </div>

                </div>

                <div class="col-12 row mx-0 px-4 mt-3 mb-3">

                    <div class="col-12 d-flex align-items-center">

                        <input class="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked"
                            [checked]="is_default==true" (click)="makeUsDefault($event)">

                        <label class="form-check-label fz-14 ms-2" for="flexCheckChecked">

                            Make us default

                        </label>

                    </div>

                </div>

                <div class="col-12 row mx-0 border-top">

                    <div class="col-12 d-flex justify-content-center px-4 mt-3">

                        <button class="address-btn-three fz-14"
                            [disabled]="full_name==''||contact==''||building_name==''||street_name==''||city==''||nationality==''||state==''"
                            *ngIf="updateBtnActive==false" (click)="addresCreate()">Create new Address</button>

                        <button class="address-btn-three fz-14" *ngIf="updateBtnActive==true"
                            (click)="updateAddress()">Update Address</button>

                    </div>

                </div>

            </div>
            <div class="dialog-container" *ngIf="otpDisplay == true">

                <p-dialog header="Otp Verification" class="" [(visible)]="otpDisplay">

                    <div class="col-12 row mx-0 d-flex justify-content-center">

                        <ng-otp-input class="px-0" header="GeeksforGeeks" (onInputChange)="onOtpChange($event)"
                            [config]="{length:5}"></ng-otp-input>

                        <button *ngIf="updateBtnActiveOtp==false" class="fz-15 fw-400 w-100 mt-3 mx-1 login-btn py-2"
                            [disabled]="button_active==false&&subscribeTimer==0"
                            (click)="addresVarification()">Send</button>

                        <button *ngIf="updateBtnActiveOtp==true" class="fz-15 fw-400 w-100 mt-3 mx-1 login-btn py-2"
                            [disabled]="button_active==false&&subscribeTimer==0"
                            (click)="UpdateaddresVarification()">Send</button>

                        <div class="col-12 px-1 mt-2 d-flex justify-content-center">

                            <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" *ngIf="subscribeTimer!=0">{{subscribeTimer}}
                                sec</i>

                            <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" *ngIf="subscribeTimer==0"
                                (click)="reSendOtp()">Resend Otp</i>

                        </div>

                    </div>

                </p-dialog>

            </div>
        </div>
    </div>
</div>