    <!-- section 6 -->
    <div class="container-fluid  section6bgc ">
        <div class="container">
            <div class=" col-12 row py-sm-5 py-4 mx-0 d-none d-sm-flex align-items-center justify-content-center">
                <div class="d-flex flex-row align-items-center justify-content-center">
                    <h3 class="text-white mb-1 section6-text1">We're Always Here To Help You ?</h3>
                    <!-- <button type="button" class="section6-btn1 px-3 py-2">RECIEVE NEWS</button> -->
                    <button type="button" class="section6-btn2 px-5 py-2 ms-4" (click)="sendEmail()">SEND MAIL</button>
                </div>
            </div>

            <!-- div duplication for responsive -->
            <div class=" col-12 row py-sm-5 py-4 mx-0 d-block d-sm-none">
                <div
                    class=" text-center col-sm-6 col-12 d-flex align-items-center justify-content-sm-start justify-content-center text-center">
                    <h3 class="text-white mb-1 section6-text1">We're Always Here To Help You ?</h3>
                </div>
                <div
                    class="col-sm-6 col-12 d-flex align-items-center justify-content-sm-end justify-content-center pt-sm-0 pt-3 pe-sm-5 pe-0">
                    <!-- <button type="button" class="section6-btn1 px-3 py-2">RECIEVE NEWS</button> -->
                    <button type="button" class="section6-btn2 px-5 py-2" (click)="sendEmail()">SEND MAIL</button>
                </div>
            </div>
        </div>
    </div>

    <!-- footer  -->
    <div class="container-fluid mx-0 py-4 footer">
        <div class="col-12 row px-lg-5 ms-1">
            <div class="logo col-md-3 col-12 d-flex align-items-center justify-content-md-start justify-content-center">
                <img src="../../../assets/icons/Sidra Logo white-16 (1).svg" width="100px" alt="">
            </div>
            <div class="col-md-6 col-12 d-flex align-items-center justify-content-center mt-md-0 mt-3 px-0">
                <span class="copyright"> © 2022 Sidracartuae.com All Rights Reserved</span>
                <span class="privacytag ms-2 cursor d-none d-md-flex" [routerLink]="['/genaral-policy']">privacy &
                    policy</span>
                <span class="privacytag ms-2 cursor d-none d-md-flex" [routerLink]="['/termsanduse']">Terms-use</span>
            </div>
            <div class="col-12 d-flex d-md-none align-items-center justify-content-center mt-1">
                <span class="privacytag ms-2 cursor" [routerLink]="['/genaral-policy']">privacy & policy</span>
                <span class="privacytag ms-2 cursor" [routerLink]="['/termsanduse']">Terms-use</span>
            </div>
            <div
                class="col-md-3 col-12 d-flex align-items-center justify-content-md-end justify-content-center  mt-md-0 mt-3">
                <button class="iconborder px-2 me-2" (click)="linkClick('twitter')"><span uk-icon="twitter"></span></button>
                <button class="iconborder px-2 me-2" (click)="linkClick('facebook')"><span uk-icon="facebook"></span></button>
                <button class="iconborder px-2 me-2" (click)="linkClick('whatsapp')"><span uk-icon="whatsapp"></span></button>
                <button class="iconborder px-2 " (click)="linkClick('insta')"><span uk-icon="instagram"></span></button>
            </div>
        </div>
    </div>
